import MenuService from '@/services/MenuService';

export const state = () => ({
  data: {},
});

export const mutations = {
  setMenuData(state, { data }) {
    state.data = data;
  },
};

export const actions = {
  async fetchMenu({ commit }) {
    const menuService = new MenuService({
      http: this.$http,
      auth: this.$auth,
    });

    try {
      const response = await menuService.fetchMenu({
        languageIsoCode: this.$i18n.locale,
      });
      commit('setMenuData', { data: response.data });

      return response;
    } catch (error) {
      console.log('menu error', error);

      throw error;
    }
  },
};
